<template>
  <div class="media align-items-stretch justify-content-center ht-100p">
    <div class="sign-wrapper mg-lg-r-50 mg-xl-r-60">
      <div class="pd-t-20 wd-100p">
        <h4 class="tx-color-01 mg-b-5">{{ ('Create New Account') }}</h4>
        <p class="tx-color-03 tx-16 mg-b-40">It's free to signup and only takes a minute.</p>

        <form method="POST" @submit.prevent="register" @keydown="form.onKeydown($event)" autocomplete="off">

          <div class="form-group">
            <label>{{ ('Name') }}</label>
            <input id="name" name="name" type="text" v-model="form.name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }" required autocomplete="name" autofocus  placeholder="Enter your name">
            <has-error :form="form" field="name" />
          </div>
          
          <div class="form-group">
            <label>{{ ('E-Mail Address') }}</label>
            <input type="email" name="email" v-model="form.email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" required autocomplete="email" autofocus placeholder="yourname@yourmail.com">

            <has-error :form="form" field="email" />
          </div>
          
          <div class="form-group">
            <div class="d-flex justify-content-between mg-b-5">
              <label class="mg-b-0-f">{{ ('Password') }}</label>
            </div>
            <input id="password" name="password" type="password" v-model="form.password" class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" required autocomplete="current-password" placeholder="Enter your password">
            <has-error :form="form" field="password" />
          </div>
          
          <div class="form-group">
            <div class="d-flex justify-content-between mg-b-5">
              <label class="mg-b-0-f">{{ ('Confirm Password') }}</label>
            </div>
            <input id="password_confirmation" name="password_confirmation" v-model="form.password_confirmation" type="password" class="form-control" :class="{ 'is-invalid': form.errors.has('password_confirmation') }" required autocomplete="current-password" placeholder="Confirm your password">
            <has-error :form="form" field="password_confirmation" />
          </div>
          
          <div class="form-group tx-12">
            By clicking <strong>Create an account</strong> below, you agree to our terms of service and privacy statement.
          </div><!-- form-group -->

          <button class="btn btn-brand-02 btn-block">{{ ('Create Account') }}</button>
        </form>
        <div v-if="false" class="divider-text">or</div>
        <FacebookLogin v-if="false">Sign Up With Facebook</FacebookLogin>
        <GoogleLogin v-if="false">Sign Up With Google</GoogleLogin>
        <div class="tx-13 mg-t-20 tx-center">Already have an account? <router-link :to="{ name: 'login' }">{{ ('Sign In') }}</router-link></div>
      </div>
    </div><!-- sign-wrapper -->
    <div class="media-body align-items-center d-none d-lg-flex">
      <div class="mx-wd-600">
        <img :src="src" class="img-fluid w-100" alt="">
      </div>
    </div><!-- media-body -->
  </div><!-- media -->
</template>

<script>
  import { Form } from 'vform'
  import GoogleLogin from '~/components/GoogleLogin'
  import FacebookLogin from '~/components/FacebookLogin'

  export default {
    layout: 'auth',

    data(){
      return {
        form: new Form({
          name: '',
          email: '',
          password: '',
          password_confirmation: ''
        }),
        src:  "/images/family-0" + Math.ceil(Math.random() * 5) + '.svg'
      }
    },
    components: {
      GoogleLogin,
      FacebookLogin
    },
    methods: {
      async register () {
        // Register the user.
        const { data } = await this.form.post('/api/v1/register');

        // Save the token.
        this.$store.dispatch('auth/saveToken', {
          token: data.token,
          remember: this.remember
        });

        // Update the user.
        await this.$store.dispatch('auth/fetchUser');
        await this.$store.dispatch('account/fetchChurch');

        // Redirect home.
        this.$router.push({ name: 'home' });

      }
    }
  }
</script>

<style lang="scss" scoped>
.btn-outline-google {
  background-color: transparent;
  border-color: #00a7e6;
  color: #00a7e6; 
  &:hover, &:focus {
    background-color: #00a7e6;
    border-color: #00a7e6;
    color: #fff; 
  }
}
</style>