<template>
  <button class="btn btn-outline-google btn-block" type="button" v-on:click="login">
    <slot>{{ $t('login_with') }} <fa :icon="['fab', 'google']" /></slot>
  </button>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  name: 'GoogleLogin',

  computed: {
    googleClientId: () => process.env.VUE_APP_GOOGLE_CLIENT_ID,
    url: () => `/api/v1/oauth/google`
  },

  mounted () {
    window.addEventListener('message', this.onMessage, false)
  },

  beforeDestroy () {
    window.removeEventListener('message', this.onMessage)
  },

  methods: {
    async login () {
      const newWindow = openWindow('', this.$t('login'))

      const url = await this.$store.dispatch('auth/fetchOauthUrl', {
        provider: 'google'
      })

      newWindow.location.href = url
    },

    /**
     * @param {MessageEvent} e
     */
    onMessage ({data: {status, message, token, provider}}) {
      if (provider && provider == 'google') {
        if (status == "error") {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
          console.log("error", message);
        } else {
          this.$store.dispatch('auth/saveToken', {
            token: token
          })

          this.$router.push({ name: 'home' })
        }
      }
    }
  }
}

/**
 * @param  {Object} options
 * @return {Window}
 */
function openWindow (url, title, options = {}) {
  if (typeof url === 'object') {
    options = url
    url = ''
  }

  options = { url, title, width: 600, height: 720, ...options }

  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
  const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
  const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

  options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
  options.top = ((height / 2) - (options.height / 2)) + dualScreenTop

  const optionsStr = Object.keys(options).reduce((acc, key) => {
    acc.push(`${key}=${options[key]}`)
    return acc
  }, []).join(',')

  const newWindow = window.open(url, title, optionsStr)

  if (window.focus) {
    newWindow.focus()
  }

  return newWindow
}
</script>
